import Router from 'next/router';
import { useState, createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import { PageViewGTM } from '@/lib/gtm';

interface TransitioningProviderProps {
  children: ReactNode;
}

interface TransactionsContextData {
  isTransitioning: boolean;
  handleStartTransitioning: () => void;
  handleStopTransitioning: (url) => void;
}

const TransitioningContext = createContext({} as TransactionsContextData);

export function TransitioningProvider({ children }: TransitioningProviderProps) {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const alreadyContainedTruvid = useRef(false);
  const alreadyContainedMgid = useRef(false);

  const appendScriptTruvid = () => {
    const existingComponent = document.getElementById('truvidPos');
    if (!existingComponent) return;

    const existingScript = document.getElementById('truvid');
    if (existingScript) return;

    const script = document.createElement('script');
    script.id = 'truvid';
    script.src = 'https://cnt.trvdp.com/js/455/9332.js';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    const truvidParent = document.getElementById('truvidPos');
    truvidParent.appendChild(script);
  };

  const decreaseScriptTruvid = () => {
    const truvidParent = document.getElementById('truvidPos');
    if (!truvidParent) return;

    while (truvidParent && truvidParent.firstChild) {
      truvidParent.removeChild(truvidParent.firstChild);
    }

    appendScriptTruvid();
  };

  const appendScriptMgid = () => {
    const existingComponentMobile = document.getElementById(`mgidContainerCTmobile`);
    const existingComponentDesktop = document.getElementById(`mgidContainerCTdesktop`);
    if (!existingComponentDesktop || !existingComponentMobile) return;

    const existingScriptMobile = document.getElementById(`mgidScriptmobile`);
    const existingScriptDesktop = document.getElementById(`mgidScriptdesktop`);
    if (existingScriptMobile && existingScriptDesktop) return;

    const divDesktop = document.createElement('div');
    divDesktop.id = 'M901213ScriptRootC1525795';
    divDesktop.className = 'mgidDesktop';

    const divMobile = document.createElement('div');
    divMobile.id = 'M901213ScriptRootC1524573';
    divMobile.className = 'mgidMobile';

    const scriptDesktop = document.createElement('script');
    scriptDesktop.id = `mgidScriptmobile`;
    scriptDesktop.src = `https://jsc.mgid.com/c/a/canaltech.com.br.1525795.js`;
    scriptDesktop.type = 'text/javascript';
    scriptDesktop.async = true;
    scriptDesktop.defer = true;

    const scriptMobile = document.createElement('script');
    scriptMobile.id = `mgidScriptmobile`;
    scriptMobile.src = `https://jsc.mgid.com/c/a/canaltech.com.br.1524573.js`;
    scriptMobile.type = 'text/javascript';
    scriptMobile.async = true;
    scriptMobile.defer = true;

    const mgidParentDesktop = document.getElementById(`mgidContainerCTdesktop`);
    mgidParentDesktop.appendChild(divDesktop);
    mgidParentDesktop.appendChild(scriptDesktop);

    const mgidParentMobile = document.getElementById(`mgidContainerCTmobile`);
    mgidParentMobile.appendChild(divMobile);
    mgidParentMobile.appendChild(scriptMobile);
  };

  const decreaseScriptMgid = () => {
    const mgidParentDesktop = document.getElementById('mgidScriptdesktop');
    if (!mgidParentDesktop) return;

    while (mgidParentDesktop && mgidParentDesktop.firstChild) {
      mgidParentDesktop.removeChild(mgidParentDesktop.firstChild);
    }

    const mgidParentMobile = document.getElementById('mgidScriptmobile');
    if (!mgidParentMobile) return;

    while (mgidParentMobile && mgidParentMobile.firstChild) {
      mgidParentMobile.removeChild(mgidParentMobile.firstChild);
    }

    appendScriptMgid();
  };

  const handleStartTransitioning = () => {
    setIsTransitioning(true);

    const existingComponentTruvid = document.getElementById('truvidPos');
    alreadyContainedTruvid.current = !!existingComponentTruvid;

    const existingComponentMgid = document.getElementById('mgidScriptmobile');
    alreadyContainedMgid.current = !!existingComponentMgid;
  };

  const handleStopTransitioning = (url: string) => {
    PageViewGTM(url);
    const { googletag } = window;
    googletag.destroySlots();
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
    setIsTransitioning(false);

    if (alreadyContainedTruvid.current) decreaseScriptTruvid();
    if (alreadyContainedMgid.current) decreaseScriptMgid();
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', handleStartTransitioning);
    Router.events.on('routeChangeComplete', handleStopTransitioning);
    return () => {
      Router.events.off('routeChangeStart', handleStartTransitioning);
      Router.events.off('routeChangeComplete', handleStopTransitioning);
    };
  }, []);

  return (
    <TransitioningContext.Provider value={{ isTransitioning, handleStartTransitioning, handleStopTransitioning }}>{children}</TransitioningContext.Provider>
  );
}

export function useTransition() {
  const context = useContext(TransitioningContext);

  return context;
}
